module OryAdmin exposing (Flags, Link, Model, Msg(..), SubModel(..), main)

import Browser exposing (Document)
import Browser.Navigation as Bn exposing (Key)
import CookieData as Cd
import CookieFunctions as Cf
import CookieView as Cv
import Css
import Css.Global
import DX.Breakpoints as Bp
import DX.Theme as Theme
import DX.Utilities as Tw
import Html.Styled exposing (Html, a, div, img, nav, text)
import Html.Styled.Attributes as Attr exposing (css)
import Http
import OryAdmin.Client.CreateOrUpdate
import OryAdmin.Client.List
import OryAdmin.Client.View
import OryAdmin.Route as R
import OryAdmin.User.CreateOrUpdate
import OryAdmin.User.List
import OryAdmin.User.View
import RemoteData as RD
import Task
import Time
import Url exposing (Url)


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , update = update
        , subscriptions = always Sub.none
        , onUrlChange = UrlChanged
        , onUrlRequest = LinkClicked
        , view = view
        }


init : Flags -> Url -> Key -> ( Model, Cmd Msg )
init _ url key =
    let
        ( model, cmd ) =
            initPage
                { route = R.parseUrl url
                , key = key
                , subModel = NotFoundModel
                , user = RD.Loading
                , url = url
                , timezone = Time.utc
                , logoutModel = Nothing
                }
    in
    ( model, Cmd.batch [ cmd, Cf.toSession (RD.fromResult >> GotSession), Cf.getLogoutModel GotLogoutUrl, Task.perform GotTimezone Time.here ] )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        GotTimezone newTimeZone ->
            ( { model | timezone = newTimeZone }, Cmd.none )

        GotSession x ->
            Cf.redirectWhenUnauthorized model.url x { model | user = x }

        GotLogoutUrl x ->
            case x of
                Result.Ok s ->
                    ( { model | logoutModel = Just s }, Cmd.none )

                _ ->
                    ( model, Cmd.none )

        LinkClicked urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    if String.startsWith "/api/.ory" url.path then
                        ( model, Bn.load (Url.toString url) )

                    else
                        ( { model | route = R.parseUrl url, url = url }, Bn.pushUrl model.key (Url.toString url) )

                Browser.External href ->
                    ( model, Bn.load href )

        UrlChanged url ->
            initPage { model | route = R.parseUrl url, url = url }

        SubModelMsg subModuleMsg ->
            case ( subModuleMsg, model.subModel ) of
                ( GotClientListMsg subMsg, ClientList subModel ) ->
                    OryAdmin.Client.List.update { handleApiError = Cf.redirectWhenUnauthorized model.url } subMsg subModel
                        |> updateWith ClientList GotClientListMsg model

                ( GotClientViewMsg subMsg, ClientView subModel ) ->
                    OryAdmin.Client.View.update subMsg subModel
                        |> updateWith ClientView GotClientViewMsg model

                ( GotUserListMsg subMsg, UserList subModel ) ->
                    OryAdmin.User.List.update subMsg subModel
                        |> updateWith UserList GotUserListMsg model

                ( GotUserViewMsg subMsg, UserView subModel ) ->
                    OryAdmin.User.View.update subMsg subModel model.timezone
                        |> updateWith UserView GotUserViewMsg model

                ( GotUserCreateOrUpdateMsg subMsg, UserCreateOrUpdate subModel ) ->
                    OryAdmin.User.CreateOrUpdate.update subMsg subModel
                        |> updateWith UserCreateOrUpdate GotUserCreateOrUpdateMsg model

                ( GotClientCreateOrUpdateMsg subMsg, ClientCreateOrUpdate subModel ) ->
                    OryAdmin.Client.CreateOrUpdate.update subMsg subModel
                        |> updateWith ClientCreateOrUpdate GotClientCreateOrUpdateMsg model

                _ ->
                    ( model, Cmd.none )


initPage : Model -> ( Model, Cmd Msg )
initPage model =
    case model.route of
        R.NotFound ->
            ( { model | subModel = NotFoundModel }, Cmd.none )

        R.Home ->
            ( { model | subModel = HomeModel }, Cmd.none )

        R.UserList ->
            OryAdmin.User.List.init
                |> updateWith UserList GotUserListMsg model

        R.UserView id ->
            OryAdmin.User.View.init id
                |> updateWith UserView GotUserViewMsg model

        R.UserUpdate id ->
            OryAdmin.User.CreateOrUpdate.init (Just id)
                |> updateWith UserCreateOrUpdate GotUserCreateOrUpdateMsg model

        R.UserCreate ->
            OryAdmin.User.CreateOrUpdate.init Nothing
                |> updateWith UserCreateOrUpdate GotUserCreateOrUpdateMsg model

        R.ClientView id ->
            OryAdmin.Client.View.init id
                |> updateWith ClientView GotClientViewMsg model

        R.ClientList ->
            OryAdmin.Client.List.init
                |> updateWith ClientList GotClientListMsg model

        R.OauthClientCreate ->
            OryAdmin.Client.CreateOrUpdate.init Nothing
                |> updateWith ClientCreateOrUpdate GotClientCreateOrUpdateMsg model

        R.OauthClientUpdate id ->
            OryAdmin.Client.CreateOrUpdate.init (Just id)
                |> updateWith ClientCreateOrUpdate GotClientCreateOrUpdateMsg model


updateWith : (a -> b) -> (c -> SubModelMsg) -> { d | subModel : b } -> ( a, Cmd c ) -> ( { d | subModel : b }, Cmd Msg )
updateWith toModel toMsg model ( subModel, subCmd ) =
    ( { model | subModel = toModel subModel }, Cmd.map (SubModelMsg << toMsg) subCmd )


type alias Flags =
    {}


type SubModelMsg
    = GotClientListMsg OryAdmin.Client.List.Msg
    | GotClientViewMsg OryAdmin.Client.View.Msg
    | GotUserListMsg OryAdmin.User.List.Msg
    | GotUserViewMsg OryAdmin.User.View.Msg
    | GotUserCreateOrUpdateMsg OryAdmin.User.CreateOrUpdate.Msg
    | GotClientCreateOrUpdateMsg OryAdmin.Client.CreateOrUpdate.Msg


type Msg
    = LinkClicked Browser.UrlRequest
    | GotLogoutUrl (Result Http.Error Cd.LogoutModel)
    | GotSession (RD.WebData Cd.Session)
    | GotTimezone Time.Zone
    | UrlChanged Url.Url
    | SubModelMsg SubModelMsg


type SubModel
    = HomeModel
    | NotFoundModel
    | ClientList OryAdmin.Client.List.Model
    | ClientView OryAdmin.Client.View.Model
    | UserList OryAdmin.User.List.Model
    | UserView OryAdmin.User.View.Model
    | UserCreateOrUpdate OryAdmin.User.CreateOrUpdate.Model
    | ClientCreateOrUpdate OryAdmin.Client.CreateOrUpdate.Model


type alias Model =
    { route : R.Route
    , key : Key
    , timezone : Time.Zone
    , subModel : SubModel
    , user : RD.WebData Cd.Session
    , logoutModel : Maybe Cd.LogoutModel
    , url : Url
    }


type alias Link =
    { title : String, routeType : R.Route }


viewNavLink : R.Route -> Link -> Html Msg
viewNavLink activeRoute { title, routeType } =
    a
        [ Attr.href <| R.toString routeType
        , css <|
            [ Tw.inline_flex
            , Tw.items_center
            , Tw.px_1
            , Tw.pt_1
            , Tw.border_b_2
            , Tw.text_sm
            , Tw.font_medium
            ]
                ++ (if activeRoute == routeType then
                        [ Tw.border_color Theme.indigo_500, Tw.text_color Theme.gray_900 ]

                    else
                        [ Tw.border_color Theme.transparent, Tw.text_color Theme.gray_500, Css.hover [ Tw.border_color Theme.gray_300, Tw.text_color Theme.gray_700 ] ]
                   )
        ]
        [ text title ]


viewHeader : Model -> Html Msg
viewHeader model =
    let
        navLinks : List Link
        navLinks =
            [ { title = "Home", routeType = R.Home }
            , { title = "Users", routeType = R.UserList }
            , { title = "Create User", routeType = R.UserCreate }
            , { title = "Clients", routeType = R.ClientList }
            , { title = "Create Client", routeType = R.OauthClientCreate }
            ]
    in
    div
        [ css
            [ Tw.flex
            , Tw.justify_between
            , Tw.h_16
            ]
        ]
        [ div
            [ css [ Tw.flex ] ]
            [ div
                [ css
                    [ Tw.flex_shrink_0
                    , Tw.flex
                    , Tw.items_center
                    ]
                ]
                [ img
                    [ css
                        [ Tw.block
                        , Tw.h_8
                        , Tw.w_auto
                        ]
                    , Attr.src "/static/logo.svg"
                    , Attr.alt "Workflow"
                    ]
                    []
                ]
            , div
                [ css
                    [ Tw.neg_my_px
                    , Tw.ml_6
                    , Tw.flex
                    , Tw.space_x_8
                    ]
                ]
                (List.map (viewNavLink model.route) navLinks)
            ]
        , div [ css [ Tw.ml_6, Tw.flex, Tw.items_center ] ]
            [ div
                [ css
                    [ Tw.flex
                    , Tw.items_center
                    , Tw.px_4
                    ]
                ]
                [ div
                    [ css
                        [ Tw.flex_shrink_0
                        ]
                    ]
                    [ Cv.viewHeaderBlock model.url model.logoutModel model.user
                    ]
                ]
            ]
        ]


viewContent : Model -> Html Msg
viewContent model =
    let
        activeView : Html Msg
        activeView =
            case model.subModel of
                UserList s ->
                    Html.Styled.map (SubModelMsg << GotUserListMsg) (OryAdmin.User.List.view s model.timezone)

                UserView s ->
                    Html.Styled.map (SubModelMsg << GotUserViewMsg) (OryAdmin.User.View.view s model.timezone)

                UserCreateOrUpdate s ->
                    Html.Styled.map (SubModelMsg << GotUserCreateOrUpdateMsg) (OryAdmin.User.CreateOrUpdate.view s)

                NotFoundModel ->
                    text "Route not found"

                HomeModel ->
                    text "Home"

                ClientView subModel ->
                    Html.Styled.map (SubModelMsg << GotClientViewMsg) (OryAdmin.Client.View.view subModel model.timezone)

                ClientList subModel ->
                    Html.Styled.map (SubModelMsg << GotClientListMsg) (OryAdmin.Client.List.view subModel model.timezone)

                ClientCreateOrUpdate s ->
                    Html.Styled.map (SubModelMsg << GotClientCreateOrUpdateMsg) (OryAdmin.Client.CreateOrUpdate.view s)
    in
    div
        [ css
            [ Tw.py_10
            ]
        ]
        [ activeView
        ]


layout : Model -> Html Msg
layout model =
    nav
        [ css
            [ Tw.bg_color Theme.white
            , Tw.border_b
            , Tw.border_color Theme.gray_200
            ]
        ]
        [ div
            [ css
                [ Tw.max_w_7xl
                , Tw.mx_auto
                , Tw.px_4
                , Bp.lg
                    [ Tw.px_8
                    ]
                , Bp.sm
                    [ Tw.px_6
                    ]
                ]
            ]
            [ viewHeader model
            , viewContent model
            ]
        ]


view : Model -> Document Msg
view model =
    { title = "Ory-admin"
    , body =
        [ Html.Styled.toUnstyled <| Css.Global.global Tw.globalStyles
        , div []
            [ div
                [ css
                    [ Tw.min_h_full
                    ]
                ]
                [ layout model ]
            ]
            |> Html.Styled.toUnstyled
        ]
    }
